<template>
  <div>

    <!-- Desktop Navigation -->

    <nav class="relative flex items-center justify-between px-4 py-6 sm:px-6 max-w-screen-xl mx-auto">
      <div class="flex items-center flex-1">
        <div class="flex items-center justify-between w-full md:w-auto">
          <a href="/" aria-label="Home">
            <BaseLogo :variant="variantStyle.logo"/>
          </a>
          <div class="-mr-2 flex items-center md:hidden">
            <button type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:bg-gray-700 focus:outline-none focus:bg-gray-700 transition duration-150 ease-in-out" id="main-menu" aria-label="Main menu" aria-haspopup="true" @click="toggleMobileNavigation(true)">
              <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="hidden md:flex items-center space-x-10">
<!--         <a :class="variantStyle.links" class="text-lg font-medium transition duration-150 ease-in-out" href="/faq">
          FAQ
        </a> -->
        <a :class="variantStyle.links" class="text-lg font-medium transition duration-150 ease-in-out" href="/events">
          Log in
        </a>
        <BaseButton :link="true" :variant="variantStyle.button" href="/events/new" >
          Sign Up
        </BaseButton>
      </div>
    </nav>

    <!-- Mobile Navigation -->

    <div v-show="showMobileNavigation" class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
      <div class="rounded-lg shadow-md">
        <div class="rounded-lg bg-white shadow-xs overflow-hidden" role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
          <div class="px-5 pt-4 flex items-center justify-between">
            <div>
              <BaseLogo/>
            </div>
            <div class="-mr-2">
              <button type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" aria-label="Close menu" @click="toggleMobileNavigation(false)">
                <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
          <div class="space-y-1 px-2 pt-2 pb-3">
<!--             <a class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem" href="/faq">
              FAQ
            </a> -->
            <a class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem" href="#">
              Log in
            </a>
          </div>
          <div>
            <a href="#" class="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100 hover:text-indigo-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700 transition duration-150 ease-in-out" role="menuitem">
              Sign Up
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { ref, computed } from 'vue'

  export default {
    name: "MarketingNavigation",

    props: {
      variant: {
        type: String,
        default: 'default',
        validator(v) {
          return ['default', 'inverted'].indexOf(v) !== -1
        },
      },
    },

    setup(props) {
      const { variant } = props

      const showMobileNavigation = ref(false)

      const linkStyle =
        (variant === 'inverted') ?
        'text-white hover:text-gray-300'
        : 'text-gray-700 hover:text-brand'

      const variantStyle = {
        logo: variant,
        links: linkStyle,
        button: variant,
      }

      return {
        showMobileNavigation,
        variantStyle,
      }
    },

    methods: {
      toggleMobileNavigation(open) {
        this.showMobileNavigation = open;
      }
    },
  }
</script>
